import React, { useCallback, useState } from 'react';
import { Drawer, notification, Upload } from 'antd';
import { useRecoilValue } from 'recoil';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { AppUpload } from '@kernex/common';
import AppMediaLibrary, { AppMediaLibraryProps } from '../AppMediaLibrary';
import Button from '../../../common/components/Button';
import appsAtom from '../../../apps/state';
import api from '../../../../api';

interface AppMediaLibraryModalProps extends Partial<AppMediaLibraryProps> {
  buttonText?: string;
  renderTrigger?: (onClick: () => void) => React.ReactNode;
  disabled?: boolean;
}

export default function AppMediaLibraryDrawer(props: AppMediaLibraryModalProps) {
  const {
    buttonText = 'Media Library', renderTrigger, onSelect, disabled, onSelectMultiple, ...mediaLibraryProps
  } = props;
  const [visible, setVisible] = useState(false);
  const { app } = useRecoilValue(appsAtom);
  const [loading, setLoading] = useState(false);
  const [newUploads, setNewUploads] = useState<AppUpload[]>([]);

  const onOpen = () => { setVisible(true); };

  const onPageChange = useCallback(() => {
    setNewUploads([]);
  }, []);

  return (
    <>
      {
        renderTrigger ? renderTrigger(onOpen) : <Button onClick={onOpen} disabled={disabled}>{buttonText}</Button>
      }
      <Drawer
        title="Media Library"
        open={visible}
        onClose={() => { setVisible(false); }}
        size="large"
        extra={(
          <Upload
            className="ms-2"
            style={{ width: '100%' }}
            accept="image/*"
            customRequest={(request) => {
              if (app?._id && request.file instanceof Blob) {
                setLoading(true);
                api.appUploads(app?._id).create({
                  file: request.file,
                }).then((response) => {
                  if (request.onSuccess) {
                    request.onSuccess(response);
                  }
                  notification.success({
                    message: 'File Uploaded',
                  });
                  setNewUploads((prev) => [response, ...prev]);
                  setLoading(false);
                }).catch((error) => {
                  if (request.onError) {
                    request.onError(error);
                  }
                  notification.error({
                    message: 'Something went wrong',
                    description: 'Please try again',
                  });
                  setLoading(false);
                });
              } else {
                notification.error({
                  message: 'Something went wrong',
                  description: 'Please try again',
                });
              }
            }}
            showUploadList={false}
          >
            <Button icon={loading ? <LoadingOutlined /> : <PlusOutlined />}>
              Upload
            </Button>
          </Upload>
        )}
      >
        {
          app && (
            <AppMediaLibrary
              appId={app._id}
              onSelect={onSelect ? (upload) => {
                onSelect(upload);
                setVisible(false);
              } : undefined}
              newUploads={newUploads}
              setNewUploads={setNewUploads}
              onPageChange={onPageChange}
              onSelectMultiple={onSelectMultiple ? (selected) => {
                onSelectMultiple(selected);
                setVisible(false);
              } : undefined}
              {...mediaLibraryProps}
            />
          )
        }
      </Drawer>
    </>
  );
}
