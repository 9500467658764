import {
  ButtonProps, Card, Checkbox, Image,
} from 'antd';
import { AppUpload } from '@kernex/common';
import { DeleteOutlined, DragOutlined, EditOutlined } from '@ant-design/icons';
import styles from '../AppMediaLibrary/index.module.css';
import { formatDate } from '../../../common/utils/format';
import Button from '../../../common/components/Button';

interface ImageCardProps extends Pick<AppUpload, 'url' | 'title' | 'createdAt'> {
  onSelect?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onCheck?: () => void;
  checked?: boolean;
  handleProps?: ButtonProps;
}

export default function ImageCard(props: ImageCardProps) {
  const {
    url,
    title,
    createdAt,
    onSelect,
    onEdit,
    onDelete,
    onCheck,
    checked,
    handleProps,
  } = props;

  return (
    <Card
      className={styles.imageCard}
      size="small"
    >
      <div className="d-flex flex-column h-100">
        <Image
          src={url}
          className={styles.image}
        />
        <div className="d-flex flex-column justify-content-between p-2 flex-grow-1">
          <div>
            <p className="text-sm m-0">{title || 'N/A'}</p>
            <p className="m-0 text-muted">{createdAt ? formatDate(createdAt) : ''}</p>
          </div>
          <div className="d-flex justify-content-end mt-2 gap-2 align-items-center">
            {
              onSelect && (
                <Button
                  size="small"
                  style={{ flex: 1 }}
                  onClick={onSelect}
                  type="primary"
                >
                  Select
                </Button>
              )
            }
            {
              onEdit && (
                <Button
                  size="small"
                  onClick={onEdit}
                >
                  <EditOutlined key="edit" />
                </Button>
              )
            }
            {
              onDelete && (
                <Button
                  size="small"
                  onClick={onDelete}
                  danger
                >
                  <DeleteOutlined />
                </Button>
              )
            }
            {
              onCheck && (
                <Checkbox
                  checked={checked}
                  onClick={onCheck}
                />
              )
            }
            {
              handleProps && (
                <Button size="small" {...handleProps}>
                  <DragOutlined />
                </Button>
              )
            }
          </div>
        </div>
      </div>
    </Card>
  );
}
